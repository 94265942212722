<template>
  <div id="cms-a-la-carte">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">À la Carte</h2></b-col>
        <b-col>
          <router-link class="link-color" :to="{ name: 'CmsALaCarteCreate' }" @click.prevent>
            <b-button type="submit" variant="success">Add A La Carte</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9 a-la-carte-table">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <b-table
                  v-else
                  striped
                  :fields="fields"
                  :items="menuItems"
                  head-variant="light"
                  primary-key="id"
                  sticky-header="320px"
          >
            <template v-slot:cell(category_name)="category_name">
              <b class="text-info">
                <span :style="{ fontWeight: 'lighter', color: '#CBA661' }">{{ category_name.value }}</span>
              </b>
            </template>
            <template v-slot:cell(item_name)="item_name">
              <b class="text-info">
                <span :style="{ fontWeight: 'lighter', color: '#CBA661' }">{{ item_name.value }}</span>
              </b>
            </template>
            <template v-slot:cell(remark_en)="remark_en">
              <b class="text-info">
                <span :style="{ fontWeight: 'lighter', color: '#CBA661' }">{{ remark_en.value }}</span>
              </b>
            </template>
            <template v-slot:cell(price)="price">
              <b class="text-info">€{{ price.value.toFixed(2) }}</b>
            </template>
            <template v-slot:cell(id)="id">
              <b-button-group size="small">
                <router-link class="link-color" :to="{ name: 'CmsALaCarteShow', params: { id: id.value }}" @click.prevent>
                  <b-icon icon="search" style="margin-right: 1em; color: #CBA661"></b-icon>
                </router-link>
                <router-link class="link-color" :to="{ name: 'CmsALaCarteUpdate', params: { id: id.value }}" @click.prevent v-b-tooltip.hover title="edit">
                  <b-icon icon="pencil" style="margin-right: 1em; color: #CBA661"></b-icon>
                </router-link>
                <router-link class="link-color" :to="{ name: 'CmsALaCarteDelete', params: { id: id.value }}" @click.prevent v-b-tooltip.hover title="delete">
                  <b-icon icon="x" style="margin-right: 1em; color: #CBA661"></b-icon>
                </router-link>
              </b-button-group>
            </template>
          </b-table>
          <b-pagination
                  v-if="!loading"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  size="sm"
                  @change="handlePageChange"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'

  export default {
    data() {
      return {
        loading: true,
        menuItemResponse: null,
        menuItems: [],
        currentPage: 1,
        totalRows: 1,
        perPage: 20,
        orderByColumn: 'category_id',
        orderBy: 'asc',
        fields: [
          {
            key: 'category_name',
            label: 'Category'
          },
          {
            key: 'item_name',
            label: 'Item name'
          },
          {
            key: 'remark_en',
            label: 'English description'
          },
          {
            key: 'price',
            label: 'Price'
          },
          {
            key: 'id',
            label: 'Actions'
          }
        ]
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get('https://batavia-backend.herokuapp.com/api/menu-item', {
            params: {
              pageNumber: 1,
              perPage: 20,
              orderByColumn: 'category_id',
              orderBy: 'asc'
            }
          })
          .then(response => (
              this.menuItemResponse = response.data,
              this.totalRows = this.menuItemResponse.total,
              this.perPage = this.menuItemResponse.per_page,
              this.currentPage = this.menuItemResponse.current_page,
              this.menuItems = JSON.parse(JSON.stringify(this.menuItemResponse.data))
          ))
          .finally(() => {
            this.loading = false
          })
    },
    methods: {
      handlePageChange(value) {
        this.loading = true
        this.currentPage = value

        this.axios
            .get('https://batavia-backend.herokuapp.com/api/menu-item', {
              params: {
                pageNumber: this.currentPage,
                perPage: this.perPage,
                orderByColumn: this.orderByColumn,
                orderBy: this.orderBy
              }
            })
            .then(response => (
                this.menuItemResponse = response.data,
                    this.totalRows = this.menuItemResponse.total,
                    this.perPage = this.menuItemResponse.per_page,
                    this.menuItems = JSON.parse(JSON.stringify(this.menuItemResponse.data))
            ))
            .finally(() => {
              this.$router.push({ name: 'CmsALaCarte' })
              this.loading = false
            })
      }
    }
  }
</script>

<style scoped>
  .a-la-carte-table {
    overflow: auto;
    height: 400px;
  }
</style>